import React from "react"

import Nav from "./nav"
import Footer from "./footer"
import config from '../../data/config'
import '../styles/main.scss'

class Layout extends React.Component {
  render() {
    const { location, title, children, hideFooter } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    return (
      <div style={{ height: '100%'}}>
        <div
          style={{
            marginTop: '60px',
            marginLeft: `auto`,
            marginRight: `auto`,
            maxWidth: '42rem',
            padding: '2.625rem 1.3125rem',
          }}
        >
          <Nav menuLinks={config.menuLinks} />
          <main>{children}</main>
        </div>
        {
          !hideFooter &&
          <Footer />
        }
      </div>
    )
  }
}

export default Layout
