import React from 'react'
import githubLogo from '../images/github.svg'
import linkedinLogo from '../images/linkedin.svg'
import styled from 'styled-components'
import twitterLogo from '../images/twitter.svg'
import youtubeLogo from '../images/youtube.svg'

const FooterBase = ({ className }) => {
  return (
    <div className={`${className}__footer`}>
      <div>&copy;	Peter Elbaum {new Date().getFullYear()} </div>
      <div className={`${className}__links`}>
        <a className="social-link" rel="noopener noreferrer" target="_blank" href="https://www.youtube.com/channel/UCPcyffOFc7YGyDgqmvZJs7Q" style={{ marginLeft: '15px', marginTop: '5px' }}><img src={youtubeLogo}/></a>
        <a className="social-link" rel="noopener noreferrer" target="_blank" href="https://github.com/elbaumpj" style={{ marginLeft: '15px', marginTop: '5px' }}><img src={githubLogo}/></a>
        <a className="social-link" rel="noopener noreferrer" target="_blank" href="https://twitter.com/peterelbaum" style={{ marginLeft: '15px', marginTop: '5px' }}><img src={twitterLogo}/></a>
        <a className="social-link" rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/in/peter-elbaum/" style={{ marginLeft: '15px', marginTop: '5px' }}><img src={linkedinLogo}/></a>
      </div>
    </div>
  )
}

const Footer = styled(FooterBase)`
  &__footer {
    align-items: center;
    // background-color: #d9d9d9;
    bottom: 0;
    display: flex;
    float: right;
    height: 40px;
    justify-content: space-between;
    padding: 0 50px;
    width: 100%;
    position: relative;
  }
  &__links {
    display: flex;
  }
`

export default Footer